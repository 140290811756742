<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

import ChartGraduation from "@/components/widgets/chart-graduation.vue";
import ChartPoints from "@/components/widgets/chart-points";

export default {
  locales: {
    pt: {
      Team: "Time",
      Dashboard: "Painel",
      Package: "Pacote",
      Graduation: "Graduação",
      Points: "Pontos",
      "Referral link": "Link de indicação",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      "Total Bonus": "Total de Bônus",
      "Total Points": "Total de Pontos",
    },
    es: {
      Team: "Equipo",
      Dashboard: "Panel",
      Package: "Paquete",
      Graduation: "Graduación",
      Points: "Puntos",
      "Referral link": "Link de patrocineo",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      "Total Bonus": "Total de Bônus",
      "Total Points": "Total de Puntos",
    },
  },
  components: {
    Layout,
    Banners,
    Stat,
    ChartGraduation,
    ChartPoints,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      total: {
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      monthly: "inactive",

      graduation: {
        id: null,
        name: "-",
        image: null,
      },

      statData: null,
      wallets: null,
    };
  },
  methods: {
    getGraduation() {
      api.get("report/graduation").then((response) => {
        if (response.data.status == "success") {
          this.graduation.id = response.data.current.id;
          this.graduation.name = response.data.current.name;
          this.graduation.image = response.data.current.image;
        }
      });
    },
    getMonthly() {
      api.get("report/monthly").then((response) => {
        this.monthly = response.data.status;
        this.getStatData();
      });
    },
    getTotalBalance() {
      api.get("wallet/balance/network").then((response) => {
        if (response.data.status == "success") {
          this.total.balance = this.$options.filters.currency(
            response.data.balance
          );
          this.getStatData();
        }
      });
    },
    getStatData() {
      this.statData = [
        {
          title: "Usuário",
          value: this.account.user.name,
        },
        {
          title: "Status",
          value: this.monthly,
        },
        {
          title: "Saldo Disponível",
          value: this.total.balance,
        },
      ];
    },
  },
  mounted() {
    this.getMonthly();
    this.getTotalBalance();
    this.getStatData();
  },
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="row">
      <div
        v-for="stat of statData"
        :key="stat.icon"
        class="col-md-4 col-lg-4 col-xl-4"
      >
        <Stat
          :type="stat.type"
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card card-h-fix">
          <div class="card-body">
            <h5 class="mb-3 text-dark text-center">
              <i class="bx bx-line-chart mr-1"></i> Graduação Mensal
            </h5>
            <ChartGraduation />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-h-fix">
          <div class="card-body">
            <h5 class="mb-3 text-dark text-center">
              <i class="bx bx-line-chart mr-1"></i> {{ t("Total Points") }}
            </h5>
            <ChartPoints></ChartPoints>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="mb-2">
              <i class="bx bx-link mr-1"></i> {{ t("Referral link") }}
            </h5>
            <template v-if="account.user.indication">
              <div class="m-0 notranslate d-flex">
                <div class="align-self-center pb-1">
                  https://app.playprotv.com/{{ account.user.indication }}
                </div>
                <div class="ml-4 align-self-center">
                  <ShareNetwork
                    network="whatsapp"
                    url=""
                    :title="
                      'https://app.playprotv.com/' + account.user.indication
                    "
                  >
                    <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
                  </ShareNetwork>
                </div>
                <div class="ml-2 align-self-center">
                  <button
                    class="btn btn-link p-0 m-0"
                    v-clipboard:copy="
                      'https://app.playprotv.com/' + account.user.indication
                    "
                    v-on:click="$noty.show('O link foi copiado!')"
                  >
                    <i class="bx bx-copy font-size-20 text-dark"></i>
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <router-link tag="a" to="/account/indication" class="text-dark">
                Você ainda não possui um link de indicação,
                <u>clique aqui</u> para criar.
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
